@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.arquivo-input:hover {
  cursor: pointer;
}
.file-input {
  display: block;
  width: auto;
  height: auto;
  border: solid 2px #6cbbb3;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 4px 10px;
}
.file-input.selected,
.file-input:hover {
  background-color: #6cbbb3;
  cursor: pointer;
}
.file-input.selected .label,
.file-input:hover .label {
  color: #fff;
}
.file-input input {
  display: none;
}
.file-input .label {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #6cbbb3;
  font-weight: bold;
}
input:hover,
label:hover {
  background-color: #fff;
}
