@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.input-container {
  width: 100%;
}
.input-container label {
  display: block;
  font-family: 'Montserrat';
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
  font-weight: normal;
  margin-bottom: 5px;
}
.input-container input {
  display: block;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #949494;
  padding: 0 20px;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
}
.input-container ~ .input-container {
  margin-top: 10px;
}
