.chat-container {
  /* height: 100%; */
  /* width: 68%; */
  max-width: 68%;
  /* position: absolute; */
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}
.chat-container .display {
  /* height: 80%; */
  /* max-height: 80%; */
  /* width: 100%; */
  /* position: relative; */
  /* overflow-y: auto; */
  /* padding: 0 30px; */
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-order: 2;
  order: 2;
  position: relative;
  z-index: 1;
}
.chat-container .display .display-list {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transition: background 0.3s ease-out 0.1s;
  width: 100%;
  z-index: 10;
  padding: 0px 30px;
  padding-bottom: 9px;
}
.chat-container .field {
  /*height: 20%;*/
  /* height: 8%; */
  /* min-height: 70px; */
  /* max-height: 20%; */
  /* width: 100%; */
  padding: 0 30px;
  /* text-align: center; */
  min-height: 50px;
  -webkit-order: 3;
  order: 3;
  position: relative;
  -webkit-flex: none;
  flex: none;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}
.chat-container .field div ~ div {
  margin-top: 15px;
}
.swal2-title {
  font-size: 20px!important;
  font-family: 'Montserrat';
}
.swal2-content {
  font-size: 20px!important;
  font-weight: bold!important;
  margin-bottom: 30px!important;
  font-family: 'Montserrat';
}
.swal2-modal {
  width: 65%!important;
  padding: 50px 30px!important;
}
.swal2-popup .swal2-styled.swal2-confirm {
  padding: 15px 55px;
}
.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
  height: auto;
}
@media screen and (max-width: 479px) {
  .chat-container {
    width: 100%;
    max-width: 100%;
    /*.display {
      width: 100%;
      padding-bottom: 60px;
    }*/
    /*.chat-container .field {
      height: 30%;
      min-height: 70px;
      max-height: 30%;
      width: 100%;
      padding: 0 30px;
      text-align: center;
    }*/
  }
  .swal2-popup.swal2-modal.swal2-show {
    width: 95%!important;
  }
  .swal2-image {
    /* -webkit-transform: rotate(90deg); */
    /* transform: rotate(90deg); */
    /* height: 54%; */
    width: 95%;
    /* max-height: 100vw; */
    /* max-width: 100vh!important; */
    /* position: relative; */
    /* top: 5%; */
  }
  .swal2-modal {
    height: auto;
    padding: 19px 30px!important;
  }
  .swal2-popup {
    justify-content: center;
    flex-direction: inherit;
  }
}
.swal2-popup .swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 0px #fff, 0 0 0 0px rgba(50, 100, 150, 0.4);
}
