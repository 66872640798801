@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.text-input {
  display: block;
  height: 40px;
  width: 100%;
  background-color: #fff;
  position: relative;
  /* top: 5px; */
  /* -webkit-transform: translateY(-50%); */
  -ms-transform: translateY(-50%);
  /* transform: translateY(-50%); */
  margin: 15px 0px;
}
.text-input input {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: rgba(235, 235, 248, 0);
  border: solid 2px #eeeeee;
  padding: 0 36px 0 30px;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
  position: relative;
  z-index: 2;
}
.text-input input[type=number]::-webkit-inner-spin-button,
.text-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.text-input input.password-input {
  width: 40%;
  border-radius: 0!important;
}
.text-input input.password-input.confirmation {
  margin-left: 2em!important;
}
.text-input button {
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.text-input button img {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.autocomplete-container {
  height: 100px;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
}
@media screen and (max-width: 479px) {
  .text-input {
    height: 60px;
    width: 100%;
    margin: 0px 0px;
  }
  .text-input input {
    height: 100%;
    width: 100%;
    border-radius: 0;
    background-color: rgba(235, 235, 248, 0);
    border-top: solid 2px #eeeeee;
    padding: 0 53px 0 30px;
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    font-weight: normal;
  }
  .text-input button {
    right: 3px;
  }
  .autocomplete-container {
    height: 80px;
    border-radius: 0;
  }
}
