@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.user-message {
  align-self: flex-end;
  height: auto;
  width: fit-content;
  position: relative;
  padding-top: 14px;
  padding-right: 58px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 0;
}
.user-message .profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 14px;
  right: 0;
}
.user-message .username {
  font-family: 'Montserrat';
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 58px;
}
.user-message .message {
  display: inline-block;
  height: auto;
  width: auto;
  width: fit-content;
  max-width: 100%;
  border-radius: 36px 0 36px 36px;
  overflow: hidden;
}
