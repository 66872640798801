@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.feedback-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
}
.feedback-container .feedback {
  height: auto;
  height: fit-content;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
}
.feedback-container .feedback .counter-container {
  width: fit-content;
}
.feedback-container .feedback .counter-container img {
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}
.feedback-container .feedback .counter-container .counter {
  display: inline-block;
  vertical-align: middle;
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: normal;
}
.feedback-container .feedback.video .counter-container {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback-container .feedback.video video {
  display: block;
  width: 100%;
}
.feedback-container .feedback.video .stop-button {
  width: auto;
  width: fit-content;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback-container .feedback.audio {
  height: 50%;
  border: solid 2px #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
.feedback-container .feedback.audio .counter-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.feedback-container .feedback.audio .first-container {
  height: 50%;
  width: 100%;
  position: relative;
  border-bottom: solid 1px #fff;
}
.feedback-container .feedback.audio .second-container {
  height: 50%;
  width: 100%;
  position: relative;
  border-top: solid 1px #fff;
}
.feedback-container .feedback.audio .second-container .stop-button {
  width: auto;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 479px) {
  .feedback-container .feedback {
    width: 90%;
  }
}
