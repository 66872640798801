@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.user-text-message .message {
  padding: 16px 24px;
  background-color: #6cbbb3;
  font-family: 'Montserrat';
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: normal;
}
