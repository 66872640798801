.typing-message .message {
  padding: 16px 24px;
  background-color: #f7f9fa;
}
.typing-message .message .bullet {
  display: inline-block;
  height: .5em;
  width: .5em;
  border-radius: 50%;
  background: #000;
  animation-name: typing-animation;
  animation-iteration-count: infinite;
  animation-duration: .9s;
}
.typing-message .message .bullet ~ .bullet {
  margin-left: 5px;
}
.typing-message .message .bullet:nth-child(2) {
  animation-delay: .3s;
}
.typing-message .message .bullet:nth-child(3) {
  animation-delay: .6s;
}
@keyframes typing-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
