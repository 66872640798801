.main-container {
  height: 100%;
  min-height: 100%;
  width: 70%;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .main-container {
    width: 100%;
  }
}
