@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.app {
  height: 100vh;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  background-color: #f7f9fa;
}
.hide {
  display: none !important;
}
button:hover {
  cursor: pointer;
}
body {
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
body .uwy.userway_p2 .uai,
body .uwy.userway_p3 .uai {
  top: calc(4%) !important;
  left: calc(90vw) !important;
}
html {
  font-size: 16px;
}
.toastr-container {
  z-index: 99 !important;
  display: block !important;
}
@media screen and (min-width: 2560px) {
  html {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 14px !important;
  }
}
