@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.login-button {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  background-color: #184376;
  font-family: 'Montserrat';
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
