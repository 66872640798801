@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.audio-input {
  display: inline-block;
  width: auto;
  min-width: 300px;
  height: auto;
  border: solid 2px #6cbbb3;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 4px auto;
}
.audio-input.selected,
.audio-input:hover {
  background-color: #6cbbb3;
  cursor: pointer;
}
.audio-input.selected .label,
.audio-input:hover .label {
  color: #fff;
}
.audio-input input {
  display: none;
}
.audio-input .label {
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #FFF0 0;
  font-weight: bold;
}
