@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.suport-alert-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: #fff;
}
.suport-alert-container .suport-alert-content {
  height: 84%;
  width: 100%;
  position: relative;
}
.suport-alert-container .suport-alert-content .wrapper {
  width: 100%;
  padding: 0 40px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
}
.suport-alert-container .suport-alert-content img {
  height: 4.625rem;
  margin-bottom: 18px;
}
.suport-alert-container .suport-alert-content h2 {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #df595d;
  font-weight: bold;
  margin-bottom: 27px;
}
.suport-alert-container .suport-alert-content p {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: normal;
  margin-bottom: 27px;
}
.suport-alert-container .suport-alert-content .icons-container img {
  height: 3.25rem;
  width: 3.25rem;
}
.suport-alert-container .suport-alert-content .icons-container img ~ img {
  margin-left: 29px;
}
.suport-alert-container .suport-alert-footer {
  height: 16%;
  width: 100%;
  background-color: #f7f9fa;
  position: relative;
}
.suport-alert-container .suport-alert-footer .wrapper {
  width: 100%;
  padding: 0 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.suport-alert-container .suport-alert-footer p {
  font-family: 'Montserrat';
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c3c3c3;
  font-weight: bold;
}
.suport-alert-container .suport-alert-footer img {
  height: 1.125rem;
  margin-top: 10px;
}
