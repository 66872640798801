@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.list-input {
  display: block;
  height: 40px;
  width: 100%;
  background-color: #fff;
  position: relative;
  /* top: 5px; */
  /* -webkit-transform: translateY(-50%); */
  -ms-transform: translateY(-50%);
  /* transform: translateY(-50%); */
  margin: 15px 0px;
}
.list-input .dropdown-container {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 38px;
  z-index: 2;
  overflow: hidden;
}
.list-input .dropdown-container .dropdown-item {
  padding: 5px 30px;
  margin-top: 0;
  font-family: 'Montserrat';
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: normal;
  border-left: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;
  background-color: #fff;
}
.list-input .dropdown-container .dropdown-item:first-child {
  border-radius: 5px 5px 0 0;
  border-top: 2px solid #eeeeee;
}
.list-input .dropdown-container .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid #eeeeee;
}
.list-input .dropdown-container .dropdown-item:only-child {
  border-radius: 5px;
  border: 2px solid #eeeeee;
}
.list-input input {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: rgba(235, 235, 248, 0);
  border: solid 2px #eeeeee;
  padding: 0 41px 0 30px;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
  position: relative;
}
.list-input input[type=number]::-webkit-inner-spin-button,
.list-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.list-input button {
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.list-input button img {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 479px) {
  .list-input {
    height: 60px;
    width: 100%;
  }
  .list-input .dropdown-container {
    bottom: 58px;
  }
  .list-input input {
    height: 100%;
    width: 100%;
    border-radius: 0;
    background-color: rgba(235, 235, 248, 0);
    border: none;
    border-top: solid 2px #eeeeee;
    padding: 0 50px 0 30px;
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    font-weight: normal;
  }
  .list-input button {
    right: 3px;
  }
}
