.user-audio-message {
  width: 100%;
}
.user-audio-message .message {
  height: 40px;
  width: 100%;
  position: relative;
}
.user-audio-message .message audio {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
