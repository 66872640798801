.bot-video-message {
  width: 100%;
}
.bot-video-message .message {
  height: auto;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.bot-video-message .message iframe {
  width: 100%;
}
