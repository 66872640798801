html,
body,
#root {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}
#video-test {
  border-radius: 8px;
  width: 95%;
  max-width: 750px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 80px;
}
#result-video-test {
  border-radius: 8px;
  width: 95%;
  max-width: 750px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 80px;
}
.video-test {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.p {
  height: 100%;
  width: 5px;
  margin: 2px;
  border-radius: 8px;
  float: left;
}
#bar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  bottom: 40px;
  left: -40px;
  width: 30px;
  height: 20px;
  -webkit-transition: 0.1s ease all;
}
.swal2-container {
  z-index: 99999999999 !important;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.button-video-test {
  min-width: 100px;
  width: 100%;
  max-width: 256px;
  min-height: 50px;
  height: 100%;
  max-height: 60px;
  border-radius: 15px;
  border: 1px solid #483a96;
  background: #483a96;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .video-test {
    flex-direction: column;
  }
  #bar {
    bottom: 250px;
    left: 25px;
  }
}
@media screen and (max-width: 410px) {
  .video-test {
    flex-direction: column;
  }
  #bar {
    bottom: 310px;
    left: -26%;
  }
}
