@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.bot-message {
  height: auto;
  width: 100%;
  position: relative;
  padding-top: 14px;
  padding-left: 58px;
  margin-top: 15px;
}
.bot-message .profile-image {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 14px;
  left: 0;
}
.bot-message .username {
  font-family: 'Montserrat';
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: normal;
  position: absolute;
  top: 0;
  left: 58px;
  min-width: 100px;
}
.bot-message .message {
  display: block;
  height: auto;
  height: fit-content;
  width: auto;
  width: fit-content;
  border-radius: 0 36px 36px 36px;
  overflow: hidden;
}
.bot-message .message a {
  word-break: break-all;
}
