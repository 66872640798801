@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700');

form label {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #bbbbbb;
}
#root {
  overflow:auto!important;
}
.ajuda-container {
  background-color: #ebebeb;
  min-height: 100vh;
}

.ajuda-container .barra-superior {
  background-color: #5245a8;
  box-shadow: -2.3px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.ajuda-container .barra-superior > div img {
  max-width: 192px;
  max-height: 36px;
}

.ajuda-container .barra-superior > div:first-child {
  background:rgba(0,0,0,0.1);
  padding:20px 40px;
  box-shadow: -2.3px 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.ajuda-container .breadcrumb {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.ajuda-container .breadcrumb a {
  text-decoration:none;
  color:#fff;
  font-weight: 500;
  margin: 0px 0 0 65px;
}
.ajuda-container .breadcrumb strong {
  margin:0 15px;
}

.ajuda-container .content {
  content: "";
  vertical-align: top;
  height: 100%;
}

.ajuda-container .barra-lateral {
  vertical-align: top;
  padding-left:0;
  padding-right:0;
}
.ajuda-container .barra-superior > div {
  padding-top:30px;
  text-align:left;
}

.ajuda-container .barra-lateral i {
  margin-right: 1em;
}
.ajuda-container .barra-lateral-content {
  background-color: #ffffff;
  box-shadow: -2.3px 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding:30px 0px;
}
.ajuda-container .barra-lateral .copy-right {
  padding:30px 30px;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5em;
  letter-spacing: -0.6px;
  text-align: left;
  color: #a8a8a8;
}
.ajuda-container .barra-lateral .copy-right img {
  margin: 5px 0 20px 0;
}
.ajuda-container .barra-lateral .copy-right b {
  font-weight: 600;
}
.ajuda-container .barra-lateral .info-candidato {
  border-bottom:2px solid #edf0f4;
  padding:0 40px;
  padding-bottom:45px;
}
.ajuda-container .barra-lateral .info-candidato img {
  border-radius:50%;
  width: 100px;
  height: 100px;
}
.ajuda-container .info-candidato .text-container {
  padding-left:40px;
  padding-top:40px;
}
.ajuda-container .info-candidato .text-container p {
  font-family: 'Montserrat';
  font-size: 16.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: normal;
  color: #6a6c6f;
}
.ajuda-container .info-candidato .text-container span {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6a6c6f;
}
.ajuda-container .menu-container h3 {
  padding-top:30px;
  font-family: 'Montserrat';
  font-size: 12.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.56;
  letter-spacing: 1.3px;
  text-align: left;
  color: #6a6c6e;
  padding:30px 40px 0;
}
.ajuda-container .menu-container {
  padding-bottom:45px;
}
.ajuda-container .menu-container nav a {
  display:block;
  text-decoration:none;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 1.9px;
  text-align: left;
  color: #6a6c6f;
  width:100%;
  padding:35px 40px;
}
.ajuda-container .menu-container nav a.active {
  background-color:#f5f6f9;
}

.ajuda-container .page-content {
  padding: 30px;
}

.ajuda-container .page-content .dados-cadastrais {
  background-color: #ffffff;
  border: solid 1px #d3d3d3;
  padding: 35px 50px 0px 50px;
}

.ajuda-container .page-content .dados-cadastrais:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.ajuda-container .page-content .dados-cadastrais .text-container {
  vertical-align: middle;

}
.ajuda-container .page-content .dados-cadastrais .text-container h2{
  margin-bottom: 20px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #453995;
}
.ajuda-container .page-content .dados-cadastrais .text-container .btn-minha-conta {
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.03;
  letter-spacing: 1.8px;
  text-align: left;
  color: #ffffff;
  border-radius: 10px;
  padding: 15px 25px;
  display: inline-block;
  margin-top:30px;
}
.ajuda-container .page-content .dados-cadastrais .text-container .btn-minha-conta i {
  margin-right: 1em;
}
.ajuda-container .page-content .dados-cadastrais .text-container .info {
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #6a6c6e;
}
.ajuda-container .page-content .dados-cadastrais .text-container .info b {
  font-weight: 700;
}
.ajuda-container .page-content .dados-cadastrais .text-container .info a {
  font-weight: 700;
  color: #6bbef1;
}
.ajuda-container .page-content .dados-cadastrais .foto-bot {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}

.ajuda-container .card-container {
  margin-top: 30px;
  overflow-x:auto;
}

.ajuda-container .card-container .card {
  display:inline-block;
  margin-right:15px;
  margin-bottom:15px;
}
.ajuda-container .card-container .card .header span {
  background: #ffc039;
  display: inline-block;
  color: #ffffff;
  width: 130px;
  min-height:130px;
  vertical-align: middle;
  text-align:center;
  padding:30px 0;
}

.ajuda-container .card-container .card .header span img {
 display:inline;
}

.ajuda-container .card-container .card .header .number {
  font-family: 'Montserrat';
  font-size: 60.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center
}


.ajuda-container .card-container .card .text-container {
  background:#fff;
  width:100%;
  padding:30px 0;
  text-align:center;
  line-height:1.5em;
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #6a6c6e;
}
.ajuda-container .card-container .card .text-container .estado {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #6a6c6e;
}

.ajuda-container .form-ajuda input[type=text],.ajuda-container .form-ajuda textarea {
  width:100%;
  border-radius: 5px;
  background-color: #fff;
  border: solid 2px #eeeeee;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6a6c6e;
  padding:10px;
  text-indent: 10px;
}
.ajuda-container .form-ajuda .row {
  margin:20px 0;
}

.ajuda-container .form-ajuda .row > div {
  padding-left:0;
  padding-right:15px;
}
.ajuda-container .btn-submit {
  border-radius: 10px;
  background-color: #6cbbb3;
  padding:10px 20px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.03;
  letter-spacing: 1.8px;
  text-align: left;
  color: #ffffff;

}
.ajuda-container .btn-submit i {
  margin-right:1em;
}
*[readonly] {
  background-color: #eee!important;
}