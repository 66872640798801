@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.picture-container {
  width: 14vw;
  height: 14vw;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.login-container {
  height: 100%;
  width: 100%;
  padding-right: 66.666666666%;
  position: relative;
}
.login-container .form-container {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.login-container .form-container .header .wrapper {
  height: auto;
  height: fit-content;
  width: 100%;
  padding: 0 100px;
}
.login-container .form-container .header img {
  display: block;
  min-width: 14vw;
  min-height: 14vh;
  width: 200%;
  height: auto;
  margin-left: -50%;
}
.login-container .form-container .header .program-name {
  font-family: 'Montserrat';
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: normal;
  color: #d8232a;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}
.login-container .form-container .login-form {
  padding: 0 60px;
}
.login-container .form-container .login-form h2 {
  width: auto;
  width: fit-content;
  font-size: 1rem!important;
  font-family: 'Montserrat';
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 20px;
}
.login-container .form-container .login-form .forgot-password {
  display: block;
  width: auto;
  width: fit-content;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #184376;
  font-weight: bold;
  text-decoration: underline;
  margin: 0 auto;
  margin-top: 20px;
}
.login-container .form-container .password-recovery-form {
  height: 45%;
  padding: 0 60px;
}
.login-container .form-container .password-recovery-form h2 {
  width: auto;
  width: fit-content;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
  font-weight: normal;
  margin: 0 auto;
  margin-bottom: 20px;
}
.login-container .form-container .password-recovery-form .back-to-login {
  display: block;
  width: auto;
  width: fit-content;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #184376;
  font-weight: bold;
  text-decoration: underline;
  margin: 0 auto;
  margin-top: 20px;
}
.login-container .form-container .footer {
  height: 15%;
  position: relative;
}
.login-container .form-container .footer .wrapper {
  height: auto;
  height: fit-content;
  width: 100%;
  padding: 0 60px;
  position: absolute;
  left: 0;
  bottom: 3.9vh;
}
.login-container .form-container .footer img {
  height: 20px;
}
.login-container .form-container .footer p {
  font-family: 'Montserrat';
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: normal;
  color: #a9a9a9;
  font-weight: normal;
}
.login-container .form-container .footer p ~ p {
  margin-top: 10px;
}
.login-container .image-container {
  display: inline-block;
  height: 100%;
  width: 66.666666666%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  overflow: hidden;
}
.login-container .image-container img {
  height: 100%;
  width: auto;
  position: relative;
}
.login-container .image-container p {
  max-width: 70%;
  font-family: 'Montserrat';
  font-size: 5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 710px) {
  .login-container .form-container .header img {
    width: 105px;
    height: 105px;
    max-width: 16vh;
    max-height: 16vh;
    border-radius: 50%;
    margin: auto;
  }
  .login-container .form-container .header .program-name {
    margin-top: 2vh;
    width: 244px;
    text-align: center;
    font-size: 17.5px;
  }
  .input-container label {
    font-size: 0.8rem!important;
    margin-bottom: 1.4vh!important;
  }
  .login-container .form-container .login-form {
    padding: 0 26px;
  }
  .login-container .form-container .login-form h2 {
    margin: 0 auto;
    margin-bottom: 3vh;
  }
  .input-container ~ .input-container {
    margin-top: 1.3vh!important;
  }
  .login-button {
    height: 39px!important;
    letter-spacing: -0.3px !important;
  }
  .login-container .form-container .login-form .forgot-password {
    font-size: 1rem;
    font-size: 12px;
    margin-top: 4.6vh;
    letter-spacing: -0.3px;
  }
  .login-container .form-container .footer {
    display: none;
    height: 15%;
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
  .login-container .form-container .footer .wrapper {
    width: 100%;
    padding: 0 23px;
    position: absolute;
  }
  .login-container .form-container .footer p {
    font-size: 10.5px;
    letter-spacing: -0.3px;
    margin-top: 2px;
  }
  .login-container .form-container .footer img {
    height: 17px;
  }
  .login-container .form-container .header .wrapper {
    padding: 0 0px;
    padding-top: 6vh;
    padding-bottom: 5.7vh;
  }
  .login-container .form-container .footer p ~ p {
    margin-top: 4px;
  }
}
@media (max-height: 640px) {
  .input-container input {
    height: 6vh!important;
    font-size: 2.8vh!important;
  }
  .login-button {
    font-size: 3vh!important;
  }
  .login-container .form-container .login-form h2 {
    margin: 0 auto!important;
    margin-bottom: 0vh!important;
  }
  .login-container .form-container .header .wrapper {
    padding: 0 0px!important;
    padding-top: 6vh!important;
    padding-bottom: 3.7vh!important;
  }
}
@media screen and (min-width: 711px) {
  .login-container .form-container .header img {
    display: block;
    min-width: 142px;
    min-height: 142px;
    width: 142px;
    height: 142px;
    margin-left: -50%;
  }
  .login-container .form-container .header .wrapper {
    padding: 0 0px!important;
    padding-top: 6vh!important;
    padding-bottom: 3.7vh!important;
  }
  .login-container .form-container .header .program-name {
    margin-top: 2vh;
    width: 244px;
    text-align: center;
    font-size: 17.5px;
  }
  .login-container .form-container .footer {
    height: 15%;
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
  .login-container .form-container .footer .wrapper {
    width: 100%;
    padding: 0 60px;
    position: absolute;
  }
  .login-container .form-container .footer p ~ p {
    margin-top: 2px;
  }
  .login-button {
    height: 39px!important;
    letter-spacing: -0.3px !important;
  }
}
@media screen and (min-width: 711px) and (max-width: 1037px) {
  .login-container .form-container .login-form {
    padding: 0 25px!important;
  }
  .login-container .form-container .footer .wrapper {
    width: 100%!important;
    padding: 0 25px!important;
    position: absolute!important;
  }
}
