.uploadedImageFile {
  align-self: flex-end;
  height: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  padding-top: 14px;
  padding-right: 58px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 0;
  
}
@media(max-width:1024px) {
  .uploadedImageFile {
    max-width: 100%;
  }
}