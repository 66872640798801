@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.side-bar {
  height: 100%;
  width: 32%;
  border-right: solid 2px #ebebeb;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.side-bar .side-bar-header {
  height: 30%;
  min-height: 180px;
  width: 100%;
  border-bottom: solid 2px #ebebeb;
  position: relative;
}
.side-bar .side-bar-header img {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  vertical-align: middle;
}
.side-bar main {
  height: 50%;
  min-height: 240px;
  width: 100%;
  padding: 40px 30px;
  overflow: auto;
}
.side-bar main .username {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: -1px;
  color: #000;
  font-weight: bold;
}
.side-bar main section {
  margin-top: 1em;
}
.side-bar main section p {
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c0c0c0;
  font-weight: normal;
}
.side-bar main section p ~ * {
  margin-top: 1em;
}
.side-bar main section h3 {
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
  font-weight: bold;
}
.side-bar main section h3 ~ * {
  margin-top: 1em;
}
.side-bar main button {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: solid 2px #6bbef1;
  background-color: #fff;
  margin-top: 1em;
  position: relative;
}
.side-bar main button:hover {
  background-color: #6bbef1;
}
.side-bar main button img {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.side-bar footer {
  height: 20%;
  width: 100%;
  background-color: #f7f9fa;
  padding: 20px;
}
.side-bar footer p {
  font-family: 'Montserrat';
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c3c3c3;
  font-weight: normal;
  margin-bottom: 9px;
}
.side-bar footer p span {
  display: block;
  font-weight: bold;
}
.side-bar footer img {
  width: 90px;
}
@media screen and (max-width: 767px) {
  .side-bar header {
    height: 20%;
  }
}
@media screen and (max-width: 479px) {
  .side-bar {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    z-index: 20!important;
    transition: transform .4s;
  }
  .side-bar.opened {
    transform: translateX(0);
  }
  .side-bar header {
    height: 40%;
  }
}
