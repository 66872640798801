@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.radio {
  display: block;
  text-align: center;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: .5rem 1rem;
  width: 82%;
  margin: 2%;
  margin-left: auto;
  margin-right: auto;
}
.radio.selected,
.radio:hover {
  cursor: pointer;
}
.radio input {
  display: none;
}
.radio .label {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff0 0;
  font-weight: bold;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 84px;
  height: 16px;
  font-size: 10px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 4px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 121%;
  font-family: Montserrat;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
@media screen and (min-width: 480px) {
  .radio-set {
    height: auto;
    position: relative;
    /* top: 14%; */
    left: 0px;
    /* margin-bottom: 1%; */
  }
  .radio {
    display: inline-block;
    text-align: center;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: .5rem 1rem;
    margin: 5px 5px;
    width: auto;
    min-width: 96px;
  }
  .radio .label {
    font-size: 18px;
  }
}
@media screen and (max-height: 480px) and (max-width: 479px) {
  .radio {
    display: block;
    text-align: center;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    width: 82%;
    margin: 2%;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem 1rem;
  }
}
@media screen and (min-height: 481px) and (max-width: 479px) {
  .radio {
    display: block;
    text-align: center;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: .5rem 1rem;
    width: 82%;
    margin: 1.5vh;
    margin-left: auto;
    margin-right: auto;
  }
}
