.main-header {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.main-header .menu-button {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}
.main-header .menu-button div {
  height: 3px;
  width: 20px;
  background-color: #f4b200;
}
.main-header .menu-button div ~ div {
  margin-top: 3px;
}
.main-header img {
  height: 45%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 479px) {
  .main-header .menu-button {
    display: block;
  }
}
