@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.edit-input {
  display: inline-block;
  width: 100%;
  margin-top: .5em;
}
.edit-input input {
  width: 100%;
  padding: 0 .5em;
  border-radius: 15px;
  background-color: rgba(235, 235, 248, 0);
  border: solid 2px #eeeeee;
  font-family: 'Montserrat';
  font-size: 1em;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
}
.edit-input input:disabled {
  border: none;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 1em;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
}
