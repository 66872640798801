@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.help {
  height: 100%;
  width: 100%;
  padding: 16px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  z-index: 2;
  background-color: #6bbef1;
  transition: transform .4s;
  overflow-y: auto;
}
.help.opened {
  transform: translateX(0);
}
.help .help-header {
  height: auto;
  position: relative;
}
.help .help-header button {
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.help .help-header button img {
  width: 100%;
}
.help .help-header .help-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
}
.help .help-header .help-icon img {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.help .help-header h2 {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
  margin: 1em 0;
}
.help .help-header p {
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
}
.help form {
  margin-top: 30px;
}
.help form input,
.help form textarea {
  width: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  font-weight: normal;
}
.help form input:not(:first-child),
.help form textarea:not(:first-child) {
  margin-top: 20px;
}
.help form textarea {
  min-height: 100px;
}
.help form .file-input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  font-weight: normal;
  text-decoration: underline;
  margin-top: 20px;
}
.help form .file-input input[type=file] {
  display: none;
}
.help form button {
  padding: 12px 16px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #483a96;
  font-family: 'Montserrat';
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
}
.help .success-message {
  margin-top: 30px;
}
.help .success-message img {
  display: block;
  height: 50px;
  margin: 0 auto;
}
.help .success-message h3 {
  width: 70%;
  font-family: 'Montserrat';
  font-size: 1.25rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
}
.help .success-message button {
  display: block;
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #483a96;
  font-weight: normal;
  margin: 0 auto;
}
.help .loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-left: 25%;
  margin-top: 80%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.help .loader-container {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 150vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15;
}
