@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.loading-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}
.loading-container .content {
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-container img {
  display: block;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
  transition: opacity .4s;
}
.loading-container .title {
  font-family: 'Montserrat';
  font-size: 2.25rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: bold;
  text-transform: lowercase;
  margin: 0 auto;
  margin-bottom: 30px;
}
.loading-container .loading {
  height: 10px;
  width: 46px;
  position: relative;
  margin: 0 auto;
}
.loading-container .loading .bullet {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #c1c1c1;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -5px;
}
.loading-container .loading .bullet:nth-child(1) {
  top: 50%;
  transform: translateX(-18px) translateY(-50%);
  animation-name: first-bullet-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.loading-container .loading .bullet:nth-child(2) {
  animation-name: second-bullet-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.loading-container .loading .bullet:nth-child(3) {
  animation-name: third-bullet-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.loading-container .loading .bullet:nth-child(4) {
  top: 50%;
  transform: translateX(18px) translateY(-50%);
  animation-name: fourth-bullet-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes first-bullet-animation {
  0% {
    height: 0;
    width: 0;
  }
  100% {
    height: 10px;
    width: 10px;
  }
}
@keyframes second-bullet-animation {
  0% {
    transform: translateX(-18px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes third-bullet-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(18px);
  }
}
@keyframes fourth-bullet-animation {
  0% {
    height: 10px;
    width: 10px;
  }
  100% {
    height: 0;
    width: 0;
  }
}
