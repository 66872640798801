.radio-set {
  height: 100%;
  /* position: relative; */
  /* -webkit-align-items: flex-end; */
  /* align-items: flex-end; */
  /* box-sizing: border-box; */
  /* display: -webkit-flex; */
  /* display: flex; */
  /* -webkit-flex-direction: row; */
  /* flex-direction: row; */
  min-height: 62px;
  position: relative;
  z-index: 2;
  /* height: -webkit-fit-content; */
  /* height: fit-content; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  width: 100%;
  /* overflow-x: auto; */
  /* white-space: nowrap; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 0px; */
  text-transform: uppercase;
  /* background-color: #fff; */
  /* transform: translateY(-50%); */
  text-align: center;
}
@media screen and (max-width: 479px) {
  /*.radio-set {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    position: fixed;
    bottom: 0px;
    top: auto;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }*/
  .chat-container .field {
    /*height: 28%;*/
    /*min-height: 70px;*/
    /*max-height: 28%;*/
    /*width: 100%;*/
    padding: 0 0px;
    text-align: center;
  }
  /*.chat-container .display {
    height: 72%;
    max-height: 80%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    padding: 0 30px;
  }*/
}
