@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.overview {
  margin-top: 30px;
  margin-bottom: 1em;
}
.overview img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.overview .username {
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: -1px;
  color: #000;
  font-weight: bold;
  margin-top: 15px;
}
.overview .infos-title {
  font-family: 'Montserrat';
  font-size: 1.25rem;
  line-height: normal;
  letter-spacing: -1px;
  color: #898989;
  font-weight: bold;
  margin-top: 20px;
}
.overview li {
  margin-top: 1em;
}
.overview li .label {
  display: inline-block;
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #c1c1c1;
  font-weight: bold;
}
.overview .buttons-container {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2em;
}
.overview .buttons-container button {
  display: inline-block;
  border: solid 2px #6cbbb3;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 0 10px;
  font-family: 'Montserrat';
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #6cbbb3;
  font-weight: bold;
}
.overview .buttons-container button.edit-button {
  border-color: #df595d;
  color: #df595d;
}
.overview .buttons-container button.edit-button:hover {
  background-color: #df595d;
  color: #fff;
}
.overview .buttons-container button.finish-button {
  border-color: #6cbbb3;
  color: #6cbbb3;
}
.overview .buttons-container button.finish-button:hover {
  background-color: #6cbbb3;
  color: #fff;
}
