@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
.toastr-container {
  height: auto;
  height: fit-content;
  width: auto;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: block !important;
}
.toastr-container .toast {
  width: 16rem;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  margin: 8px;
  border-radius: 6px;
  position: relative;
  display: block !important;
}
.toastr-container .toast .toast-close-button {
  position: absolute;
  top: 8px;
  right: 16px;
  font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: normal;
}
.toastr-container .toast .toast-title {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: bold;
}
.toastr-container .toast .toast-message {
  font-family: 'Montserrat';
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: normal;
}
.toastr-container .toast.toast-success {
  background-color: rgba(0, 200, 0, 0.9);
}
.toastr-container .toast.toast-error {
  background-color: rgba(200, 0, 0, 0.9);
}
.toastr-container .toast.toast-warning {
  background-color: rgba(200, 200, 0, 0.9);
}
.toastr-container .toast a {
  color: #fff;
}
